import React, { useContext } from 'react'

import { BackToTopMobile } from './BackToTopMobile'
import { BackToTopDesktop } from './BackToTopDesktop'

export const BackToTop: React.FC = () => {
  return (
    <>
      <BackToTopMobile />
      <BackToTopDesktop />
    </>
  )
}

export default BackToTop
