import { useContext, useState, useEffect } from 'react'
import { css, SerializedStyles } from '@emotion/react'

import { common_color, media_mobile } from '../../../style/common_value'
import {
  side_menu_state_context,
  side_menu_close,
  header_image_inview_context,
} from '../../templates/Layout'

const header_background_color = 'white'

const header_visible = {
  backgroundColor: header_background_color,
  borderBottom: `1px solid ${common_color.gray}`,
  [media_mobile.tab]: {
    backgroundColor: 'transparent',
    borderBottom: '0px',
  },
}
const header_invisible = {
  backgroundColor: 'transparent',
  borderBottom: '0px',
}

const cursor_on = css({
  cursor: 'default',
  pointerEvents: 'auto',
})

const cursor_off = css({
  cursor: 'auto',
  pointerEvents: 'none',
})

// 戻値の型定義
type header_background_type = {
  backgroundColor: string
  borderBottom: string
}
type title_click_state_type = SerializedStyles
type overwrite_click_event_type = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void
type title_view_title_type = {
  fill: string
}
type useHeader_return_types = [
  header_background_type,
  title_click_state_type,
  overwrite_click_event_type,
  title_view_title_type
]

const useHeader = (): useHeader_return_types => {
  const side_menu_state = useContext(side_menu_state_context)
  const overwrite_click_event = useContext(side_menu_close)
  const view_header_image = useContext(header_image_inview_context)
  const url = typeof window !== 'undefined' ? window.location.pathname : ''

  const [title_click_state, set_title_click_state] = useState(cursor_off)

  const [title_view_title, set_title_view_title] = useState({
    fill: 'rgba(0,0,0,0)',
  })

  const [header_background, set_header_background] = useState(header_invisible)

  useEffect(() => {
    if (side_menu_state) {
      set_title_click_state(cursor_on)
      set_title_view_title({ fill: 'white' })
      set_header_background(header_invisible)
    } else if (url === '/' && view_header_image) {
      set_title_click_state(cursor_off)
      set_title_view_title({ fill: 'rgba(0,0,0,0)' })
      set_header_background(header_invisible)
    } else if (view_header_image) {
      set_title_click_state(cursor_on)
      set_title_view_title({ fill: 'white' })
      set_header_background(header_invisible)
    } else {
      set_title_click_state(cursor_on)
      set_title_view_title({ fill: common_color.text })
      set_header_background(header_visible)
    }
  }, [url, view_header_image, side_menu_state])

  return [
    header_background,
    title_click_state,
    overwrite_click_event,
    title_view_title,
  ]
}

export default useHeader
