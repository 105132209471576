import React from 'react'
import { WrapPageElementBrowserArgs } from 'gatsby'
import 'destyle.css'

import Layout from './src/components/templates/Layout'

export const wrapPageElement: React.FC<WrapPageElementBrowserArgs> = ({
  element,
  props,
}) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Layout {...props}>{element}</Layout>
}
