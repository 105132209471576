import { css } from '@emotion/react'

import { common_color, font_family } from './common_value'

export const global_css_core = css({
  html: {
    fontFamily: font_family.kukumincho,
    fontDisplay: 'swap',
    fontSize: '16px',
    lineHeight: '1',
    letterSpacing: '0.05rem',
    color: common_color.text,
  },
})

const font_face_kokumincho = css({
  '@font-face': {
    fontFamily: 'KokuMincho',
    fontDisplay: 'swap',
    src: "url('/web_fonts/kokumin/kokumr_1.00_subset.woff2') format('woff2')",
  },
})

const font_face_fira_code = css({
  '@font-face': {
    fontFamily: 'Fira Code',
    fontDisplay: 'swap',
    src: "url('/web_fonts/FiraCode/FiraCode-Regular.woff2') format('woff2')",
    fontWeight: 400,
    fontStyle: 'normal',
  },
})

const font_face_fira_code_VF = css({
  '@font-face': {
    fontFamily: 'Fira Code VF',
    fontDisplay: 'swap',
    src: "url('/web_fonts/FiraCode/FiraCode-VF.woff2') format('woff2')",
    fontWeight: [300, 700],
    fontStyle: 'normal',
  },
})

const global_css_style = css(
  global_css_core,
  font_face_kokumincho,
  font_face_fira_code,
  font_face_fira_code_VF
)

export default global_css_style
