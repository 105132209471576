import React, { useContext } from 'react'
import { motion } from 'framer-motion'
import { css } from '@emotion/react'

import { common_color } from '../../../style/common_value'

import {
  side_menu_state_context,
  side_menu_toggle,
  header_image_inview_context,
} from '../../templates/Layout'

const button_container_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '.4rem .4rem',
  cursor: 'pointer',
  outline: 'none',
})

const button_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  svg: {
    position: 'relative',
    top: '1px',
    path: {
      stroke: common_color.text,
      transition: 'stroke 0.5s',
    },
  },
})

const svg_white = css({
  svg: {
    path: {
      stroke: '#fff',
    },
  },
})

type path_props_type = {
  variants: {
    closed: {
      d?: string
      opacity?: number
    }
    open: {
      d?: string
      opacity?: number
    }
  }
  d?: string
  transition?: { duration: number }
}

const Path: React.FC<path_props_type> = (props) => (
  /* eslint-disable react/jsx-props-no-spreading */
  <motion.path
    fill="transparent"
    strokeWidth="3"
    strokeLinecap="round"
    {...props}
  />
  /* eslint-enable react/jsx-props-no-spreading */
)

export const MenuButton: React.FC = () => {
  const side_menu_state = useContext(side_menu_state_context)
  const overwrite_click_event = useContext(side_menu_toggle)
  const view_header_image = useContext(header_image_inview_context)
  return (
    <div
      onClick={overwrite_click_event}
      onKeyDown={overwrite_click_event}
      role="button"
      tabIndex={0}
      css={button_container_style}
    >
      <motion.button
        initial={false}
        animate={side_menu_state ? 'open' : 'closed'}
        type="button"
        css={css(
          button_style,
          view_header_image || side_menu_state ? svg_white : null
        )}
      >
        <svg width="23" height="23" viewBox="0 0 23 23">
          <title>メニューを開く</title>
          <Path
            variants={{
              closed: { d: 'M 2 2.5 L 20 2.5' },
              open: { d: 'M 3 16.5 L 17 2.5' },
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1 },
              open: { opacity: 0 },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { d: 'M 2 16.346 L 20 16.346' },
              open: { d: 'M 3 2.5 L 17 16.346' },
            }}
          />
        </svg>
      </motion.button>
    </div>
  )
}

export default MenuButton
