// ---共通カラー---
const text_color = '#545B63'

const text_color_light = '#848B93'

const link_text_color = '#0366D6'

const theme_color = '#ffc039'

const theme_color_transparent = '#FFE09C'

const common_yellow_color = '#FFC039'

const common_yellow_color_light = '#FFE09C'

const common_yellow_color_verylight = '#FFF6E5' // '#FFEECC'

const common_ocher_color = '#C69F73'

const common_ocher_color_light = '#EEE2D5'

const common_ocher_color_verylight = '#F7F2EC'

const common_gray_color = '#B0ABA4' // '#c0c8d8'

const common_gray_color_light = '#C8C4C0' // '#e0e8f8'

const common_gray_color_verylight = '#F3F2F1' // '#e0e8f8'

const common_gray_color_dark = '#746d65' // #686868'

const common_white = '#FFFFFF'

const common_black = '#000000'

const common_gray_gradation = [...Array(16)]
  .map((): string => {
    return `0 0 3px ${common_gray_color}`
  })
  .join(', ')

export const common_color = {
  text: text_color,
  text_light: text_color_light,
  link: link_text_color,
  theme: theme_color,
  theme_t: theme_color_transparent,
  yellow: common_yellow_color,
  yellow_light: common_yellow_color_light,
  yellow_verylight: common_yellow_color_verylight,
  orcher: common_ocher_color,
  orcher_light: common_ocher_color_light,
  orcher_verylight: common_ocher_color_verylight,
  gray: common_gray_color,
  gray_gradation: common_gray_gradation,
  gray_light: common_gray_color_light,
  gray_verylight: common_gray_color_verylight,
  gray_dark: common_gray_color_dark,
  white: common_white,
  black: common_black,
}

// カードに表示される画像サイズは288 * 216
const post_card_width = '360px'
const post_card_grid_width = `minmax(280px, ${post_card_width})`
export const post_card_grid_template_columns = `repeat(auto-fit, ${post_card_grid_width})`

// ---共通スペース---
export const text_space_max_width = '800px'

export const common_space = {
  s: '2rem',
  m: '4rem',
  l: '6rem',
}

// ヘッダーの高さ
export const header_height = '3.3rem'

// タイトルが長すぎる場合の判定
export const too_long_title = 55

// ---メディアクエリ START---

// @media screen and (min-width: )でモバイルファースト用に使用
export const media_mobile_value = {
  sp: '481px', // モバイルファーストの場合基本使わない 769 1025
  tab: '769px',
  desktop: '1025px',
}

const media_over_text_width_value = '951px'

// 画面サイズがテキストエリア以下になった。BacktoTopの表示判定
export const media_over_text_area = `@media screen and (min-width:${media_over_text_width_value})`

export const media_mobile = {
  sp: `@media screen and (min-width:${media_mobile_value.sp})`,
  tab: `@media screen and (min-width:${media_mobile_value.tab})`,
  over_text_area: media_over_text_area,
  desktop: `@media screen and (min-width:${media_mobile_value.desktop})`,
}

const blog_post_card_mobile_value = {
  tab: '743px',
  desktop: '1120px',
}

export const blog_post_card_mobile = {
  tab: `@media screen and (min-width:${blog_post_card_mobile_value.tab})`,
  desktop: `@media screen and (min-width:${blog_post_card_mobile_value.desktop})`,
}

// @media screen and (max-width: )でデスクトップファースト用に使用
const media_desktop_value = {
  desktop: '1024px', // デスクトップファーストの場合基本使わない
  tab: '768px',
  sp: '480px',
  sp_small: '360px',
  fold: '319px',
}

// 幅1024px以下の横長限定の特殊設定（タブレット横）
export const media_tablet_landscape = `@media (max-width:${media_desktop_value.desktop}) and (orientation: landscape)`

// 幅319px以下限定の特殊設定（デベロッパーツールのGalaxy Fold用）
export const media_galaxy_fold = `@media screen and (max-width:${media_desktop_value.fold})`

// ---メディアクエリ END---

export const font_family = {
  kukumincho:
    '"KokuMincho", "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif',
  fira_code: '"Fira Code", monospace',
  fira_code_vf: '"Fira Code VF", monospace',
}

export const effect = {
  underline: (
    input_color: string,
    line_thickness: string | number = 70
  ): string => {
    const adjust_line_thickness =
      typeof line_thickness !== 'string'
        ? String(line_thickness)
        : line_thickness
    return `linear-gradient(transparent ${adjust_line_thickness}%, ${input_color} ${adjust_line_thickness}%)`
  },
}
