module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-178723967-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<svg version=\"1.1\" id=\"_x32_\" xmlns=\"http://www.w3.org/2000/svg\" x=\"0\" y=\"0\" viewBox=\"0 0 512 512\" style=\"width:0.9em;height:0.9em\" xml:space=\"preserve\" opacity=\"1\"><style>.st0{fill:#4b4b4b}</style><path class=\"st0\" fill=\"#4b4b4b\" d=\"M141.788 352.225H370.212v-30.327H141.788zM274.607 361.714h-37.222c0 18.466-8.673 73.956-47.726 92.186 9.108 13.012 27.337 19.526 41.655 16.918 0 0 2.8 30.632 24.682 41.182 21.884-10.549 24.683-41.182 24.683-41.182 14.317 2.609 32.547-3.905 41.654-16.918-39.053-18.229-47.726-73.72-47.726-92.186zM177.6 311.784h36.582s.267-133.627-88.09-149.485c-90.86-16.308-136.686 97.633-78.106 144.496 6.597-76.115 126.274-67.686 129.614 4.989zM178.95 361.478c-3.127 22.128-23.958 33.844-42.18 33.844-19.526 0-33.843-19.534-39.053-28.642-22.136 32.547-12.228 65.734 22.127 76.81 40.358 13.012 92.423-28.634 92.423-82.012H178.95zM385.908 162.299c-88.358 15.858-88.09 149.485-88.09 149.485H334.4c3.34-72.676 123.017-81.104 129.615-4.989 58.579-46.863 12.753-160.804-78.107-144.496zM375.23 395.321c-18.222 0-39.053-11.716-42.18-33.844h-33.317c0 53.378 52.066 95.024 92.423 82.012 34.355-11.076 44.262-44.263 22.127-76.81-5.21 9.108-19.527 28.642-39.053 28.642zM275.79 311.784c0-92.202 48.053-140.866 48.053-202.855 0-55.102-39.282-94.009-67.839-108.929-28.558 14.92-67.84 53.828-67.84 108.929 0 61.989 48.054 110.653 48.054 202.855h39.572z\"/></svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images-medium-zoom/gatsby-browser.js'),
      options: {"plugins":[],"margin":0,"background":"rgba(0, 0, 0, 0.8)"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#FFC039","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
