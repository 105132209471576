import React, { useContext } from 'react'
import { css } from '@emotion/react'

import { media_mobile, media_galaxy_fold } from '../../../style/common_value'
import { side_menu_state_context } from '../../templates/Layout'

import SvgRssFeed from '../../svg_components/RssFeed'

const a_style = css({
  marginRight: '.5rem',
  [media_mobile.tab]: {
    marginRight: '1rem',
  },
  [media_galaxy_fold]: {
    marginRight: '0',
  },
})

const a_show = css({
  cursor: 'pointer',
  pointerEvents: 'auto',
})

const a_hide = css({
  cursor: 'default',
  pointerEvents: 'none',
})

const rss_wrapper_style = css({
  padding: '1rem',
  transition: 'all 0.5s',
  [media_galaxy_fold]: {
    padding: '1rem .5rem',
  },
})

const svg_style = css({
  fill: 'white',
  transition: 'fill 0.5s',
})

const rss_show = css({
  opacity: '1',
})

const rss_hide = css({
  opacity: '0',
})

export const RssButton: React.FC = () => {
  const side_menu_open = useContext(side_menu_state_context)
  return (
    <a
      href="/rss.xml"
      target="_blank"
      aria-label="RSS Feed"
      css={[a_style, side_menu_open ? a_show : a_hide]}
    >
      <div css={[rss_wrapper_style, side_menu_open ? rss_show : rss_hide]}>
        <SvgRssFeed width={18} height={18} css={svg_style} />
      </div>
    </a>
  )
}

export default RssButton
