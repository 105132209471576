import React from 'react'
import { css } from '@emotion/react'
import { animateScroll } from 'react-scroll'

import { common_color, media_over_text_area } from '../../../style/common_value'

import { SvgTriangle } from '../../svg_components/Triangle'
import useBackToTopMobile from './useBackToTopMobile'

const innner_item_centering = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
})

const back_to_top_container_style = css(innner_item_centering, {
  zIndex: 25,
  position: 'fixed',
  bottom: '0',
  right: '0',
  width: '3.3rem',
  height: '3.3rem',
  backgroundColor: 'white',
  borderTop: `1px solid ${common_color.gray}`,
  borderLeft: `1px solid ${common_color.gray}`,
  borderRadius: '4px 0 0 0',
  cursor: 'pointer',
  outline: 'none', // tabIndex設定時に枠を表示させない為の設定
  [media_over_text_area]: {
    transform: 'translate(4rem, 0)',
    // right: '-4rem',
  },
})

const back_to_top_title_style = css({
  fontSize: '.7rem',
  marginTop: '.2rem',
})

const back_to_top_svg_style = css({
  stroke: common_color.text,
  strokeWidth: '1px',
  fill: 'white',
})

export const BackToTopMobile: React.FC = () => {
  const [
    container_css_state,
    container_color_state,
    title_css_state,
    svg_css_state,
  ] = useBackToTopMobile()

  return (
    <div
      css={[
        back_to_top_container_style,
        container_css_state,
        container_color_state,
      ]}
      onClick={() => animateScroll.scrollToTop()}
      onKeyDown={() => animateScroll.scrollToTop()}
      role="button"
      tabIndex={0}
    >
      <SvgTriangle
        width="1.5rem"
        height="1.5rem"
        css={[back_to_top_svg_style, svg_css_state]}
      />
      <div css={[back_to_top_title_style, title_css_state]}>Top</div>
    </div>
  )
}

export default BackToTopMobile
