import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'
import { OrganismsSidemenuTaglistQuery } from '../../../../types/graphql-types'

import { side_menu_close } from '../../templates/Layout'
import { side_menu_children_variant } from './index'

import TagCard from '../../atoms/TagCard'

const tag_style = css({
  fontSize: '.8em',
  marginTop: '2rem',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
})

const TagList: React.FC = () => {
  const data: OrganismsSidemenuTaglistQuery = useStaticQuery(graphql`
    query OrganismsSidemenuTaglist {
      allContentfulTag(sort: { fields: name, order: ASC }) {
        nodes {
          name
          slug
          id
          blog_post {
            id
          }
        }
      }
    }
  `)
  const { nodes } = data.allContentfulTag
  const overwrite_click_event = useContext(side_menu_close)
  return (
    <motion.ul
      className="tag_list_wrapper"
      css={tag_style}
      variants={side_menu_children_variant}
    >
      {nodes.map((node) => {
        return (
          node.blog_post && (
            <TagCard
              tag_name={node.name}
              link_tag_slug={node.slug}
              tag_margin=".4rem"
              base_color="white"
              tag_fill
              key={node.id}
              overwrite_click_event={overwrite_click_event}
            />
          )
        )
      })}
    </motion.ul>
  )
}

export default TagList
