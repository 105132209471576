import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgTriangle: React.FC<props_type> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0, 0, 24, 24" {...props}>
      <path d="M24 22H0L12 2z" />
    </svg>
  )
}
export default SvgTriangle
