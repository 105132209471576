import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'

import { media_mobile } from '../../../style/common_value'
import { side_menu_children_variant } from './index'

import CategoryList from './category_list'
import TagList from './tag_list'
import { side_menu_close } from '../../templates/Layout'
import SvgHomeFill from '../../svg_components/HomeFill'
import SvgAllPostFill from '../../svg_components/AllPostFill'

const nav_items_style = css({
  textAlign: 'center',
  marginTop: '4rem',
  marginBottom: '2.5rem',
  fontSize: '1.2rem',
  [media_mobile.tab]: {
    fontSize: '1.3rem',
    marginBottom: '4rem',
  },
})

const link_item_style = css({
  display: 'flex',
  justifyContent: 'center',
  a: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem .5rem 1.5rem',
    [media_mobile.tab]: {
      padding: '2rem 1rem 2rem',
    },
    svg: {
      fill: 'white',
      marginRight: '.8rem',
    },
  },
})

export const NavItems: React.FC = () => {
  const overwrite_click_event = useContext(side_menu_close)
  return (
    <div css={nav_items_style}>
      <motion.div css={link_item_style} variants={side_menu_children_variant}>
        <Link to="/" onClick={overwrite_click_event}>
          <SvgHomeFill />
          <span>Home</span>
        </Link>
      </motion.div>
      <motion.div css={link_item_style} variants={side_menu_children_variant}>
        <Link to="/all_post" onClick={overwrite_click_event}>
          <SvgAllPostFill />
          <span>全ての記事</span>
        </Link>
      </motion.div>
      <CategoryList />
      <TagList />
    </div>
  )
}

export default NavItems
