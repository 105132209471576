import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgCategoryFill: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>メインカテゴリー</title>
      <path d="M24 22H0V8h7.262c1.559 0 2.411-.708 5.07-3H24v17zM7.262 6c.64 0 1.11-.271 2.389-1.34L7 2H0v4h7.262z" />
    </svg>
  )
}
export default SvgCategoryFill
