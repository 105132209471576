import React, { Fragment, useContext } from 'react'
import { css } from '@emotion/react'
import { motion, Variants } from 'framer-motion'

import { text_space_max_width } from '../../../style/common_value'

import NavItems from './nav_items'
import {
  side_menu_close,
  side_menu_state_context,
} from '../../templates/Layout'

const side_menu_style = css({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '0',
  right: '0',
  bottom: '0',
  zIndex: 30,
  overflow: 'auto',
  height: '100%',
  backgroundColor: 'rgba(1,1,1,0.8)',
  color: 'white',
})

const side_menu_visible_style = css({
  visibility: 'visible',
})
const side_menu_hidden_style = css({
  visibility: 'hidden',
})

const background_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
})

const side_menu_content_style = css({
  margin: 'auto',
  maxWidth: text_space_max_width,
})

const side_menu_variants = {
  open: {
    // visibility: 'visible',
    width: '100%',
    transition: {
      when: 'beforeChildren',
      width: { duration: 0.2, ease: 'easeInOut' },
      visibility: { delay: 0 },
    },
  },
  closed: {
    // visibility: 'hidden',
    width: '0%',
    transition: {
      when: 'afterChildren',
      width: { duration: 0.2, ease: 'easeInOut' },
      visibility: { delay: 0.2 },
    },
  },
}

const side_menu_content_variant = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: { staggerChildren: 0 },
  },
}

export const side_menu_children_variant: Variants = {
  open: () => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
    },
  }),
  closed: () => ({
    opacity: 0,
    y: 20,
    transition: {
      opacity: { duration: 0.05 },
      y: { delay: 0.05, duration: 0 },
    },
  }),
}

const SideMenu: React.FC = () => {
  const side_menu_state = useContext(side_menu_state_context)
  const overwrite_click_event = useContext(side_menu_close)
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  return (
    <>
      <motion.nav
        initial={false}
        animate={side_menu_state ? 'open' : 'closed'}
        variants={side_menu_variants}
        css={[
          side_menu_style,
          side_menu_state ? side_menu_visible_style : side_menu_hidden_style,
        ]}
      >
        <div
          className="background"
          css={background_style}
          onClick={overwrite_click_event}
        >
          <motion.div
            variants={side_menu_content_variant}
            css={side_menu_content_style}
          >
            <NavItems />
          </motion.div>
        </div>
      </motion.nav>
    </>
  )
}

export default SideMenu
