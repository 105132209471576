import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import { common_color } from '../../style/common_value'
import { footer_ref_context } from '../templates/Layout'

const footer_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '5rem',
  marginTop: 'auto',
  color: 'white',
  backgroundColor: common_color.gray,
  '.number': {
    fontSize: '0.8em',
  },
})

const Footer: React.FC = () => {
  const footer_ref = useContext(footer_ref_context)

  return (
    <footer css={footer_style} ref={footer_ref}>
      <p>
        <Link to="/">
          アバラバクハツ since <span className="number">2020</span>
        </Link>
      </p>
    </footer>
  )
}

export default Footer
