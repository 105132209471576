import React from 'react'
import { css } from '@emotion/react'
import { animateScroll } from 'react-scroll'

import { common_color, media_over_text_area } from '../../../style/common_value'

import { SvgBackToTop } from '../../svg_components/BackToTop'
import useBackToTopDesktop from './useBackToTopDesktop'

const back_to_top_container_style = css({
  zIndex: 25,
  bottom: '-12rem',
  // transform: 'translate(0, -12rem)',
  position: 'fixed',
  right: '2rem',
  width: '1.6rem',
  transition: 'opacity .5s ease-in-out, transform .3s ease-in-out',
  cursor: 'pointer',
  outline: 'none', // tabIndex設定時に枠を表示させない為の設定
  [media_over_text_area]: {
    // bottom: '-3rem',
    transform: 'translate(0, -10rem)',
    transition: 'opacity .5s ease-in-out, transform .3s ease-in-out',
    '&:hover': {
      transform: 'translate(0, -11rem)',
      transition: 'transform .3s ease-in-out',
      '& > div': {
        opacity: '1',
        transition: 'opacity .3s ease-in-out',
      },
    },
  },
})

const top_title_style = css({
  opacity: '0',
  position: 'absolute',
  transform: 'rotate(-90deg)',
  bottom: '4.2rem',
  right: '-2.2rem',
  whiteSpace: 'nowrap',
  transition: 'opacity .3s ease-in-out, color .3s ease-in-out',
})

const back_to_top_svg_style = css({
  stroke: common_color.text,
  strokeWidth: '3px',
  transition: 'opacity .3s ease-in-out, stroke .3s ease-in-out',
})

export const BackToTopDesktop: React.FC = () => {
  const [container_css_state, title_css_state, svg_css_state] =
    useBackToTopDesktop()

  return (
    <div
      css={[back_to_top_container_style, container_css_state]}
      onClick={() => animateScroll.scrollToTop()}
      onKeyDown={() => animateScroll.scrollToTop()}
      role="button"
      tabIndex={0}
    >
      <div css={[top_title_style, title_css_state]}>Back to Top</div>
      <SvgBackToTop css={[back_to_top_svg_style, svg_css_state]} />
    </div>
  )
}

export default BackToTopDesktop
