import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgAllPostFill: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>全ての記事</title>
      <path d="M13.744 8s1.522-8-3.335-8H2v24h20V11c0-3.419-5.247-3.745-8.256-3zM14 19H6v-1h8v1zm4-3H6v-1h12v1zm0-3H6v-1h12v1zM14.568.075C16.77 1.249 20.506 4.958 22 6.956c-1.286-.9-4.044-1.657-6.091-1.179.222-1.468-.185-4.534-1.341-5.702z" />
    </svg>
  )
}
export default SvgAllPostFill
