import React, { useState, createContext } from 'react'
import { css, Global } from '@emotion/react'
import { useInView } from 'react-intersection-observer'

import global_css_style from '../../style/global_css_style'

import GlobalHelmet from '../metadata/GlobalHelmet'
import Header from '../organisms/Header'
import SideMenu from '../organisms/SideMenu'
import Footer from '../organisms/Footer'
import BackToTop from '../organisms/BackToTop'

const contener_style = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
})

const main_style = css({
  flex: 1,
})

type layout_style = {
  children: React.ReactNode
}

export const side_menu_state_context = createContext(null)
export const side_menu_close = createContext(null)
export const side_menu_toggle = createContext(null)

export const header_image_ref_context = createContext(null)
export const header_image_inview_context = createContext(null)

export const blog_post_bottom_aside_ref_context = createContext(null)
export const blog_post_bottom_aside_inview_context = createContext(null)

export const footer_ref_context = createContext(null)
export const footer_inview_context = createContext(null)

export const Layout: React.FC<layout_style> = ({ children }) => {
  const [side_menu_state, set_side_menu_state] = useState(false)

  const [ref, inView] = useInView({
    rootMargin: '-30px 0px 0px',
  })

  const [blog_post_bottom_aside_ref, blog_post_bottom_aside_inview] = useInView(
    {
      rootMargin: '0px 0px 0px',
    }
  )
  const [footer_ref, footer_inview] = useInView({
    rootMargin: '0px 0px 0px',
  })

  const side_menu_toggle_func = () => {
    if (side_menu_state) {
      set_side_menu_state(false)
    } else {
      set_side_menu_state(true)
    }
  }

  return (
    <div id="layout">
      <Global styles={global_css_style} />
      <GlobalHelmet />

      <div id="contener" css={contener_style}>
        <side_menu_state_context.Provider value={side_menu_state}>
          <side_menu_close.Provider value={() => set_side_menu_state(false)}>
            <header_image_inview_context.Provider value={inView}>
              <side_menu_toggle.Provider value={() => side_menu_toggle_func()}>
                <Header />
              </side_menu_toggle.Provider>

              <header_image_ref_context.Provider value={ref}>
                <blog_post_bottom_aside_ref_context.Provider
                  value={blog_post_bottom_aside_ref}
                >
                  <main css={main_style}>{children}</main>
                </blog_post_bottom_aside_ref_context.Provider>
              </header_image_ref_context.Provider>

              <SideMenu />

              <footer_inview_context.Provider value={footer_inview}>
                <blog_post_bottom_aside_inview_context.Provider
                  value={blog_post_bottom_aside_inview}
                >
                  <BackToTop />
                </blog_post_bottom_aside_inview_context.Provider>
              </footer_inview_context.Provider>
            </header_image_inview_context.Provider>
          </side_menu_close.Provider>
        </side_menu_state_context.Provider>

        <footer_ref_context.Provider value={footer_ref}>
          <Footer />
        </footer_ref_context.Provider>
      </div>
    </div>
  )
}

export default Layout
