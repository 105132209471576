import React from 'react'
import Helmet from 'react-helmet'

const GlobalHelmet: React.FC = () => {
  return (
    <Helmet>
      <html lang="ja" />
      <link
        rel="icon"
        type="image/png"
        href="icons/icon-16x16.png"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="icons/icon-32x32.png"
        sizes="32x32"
      />
      <link
        rel="preload"
        href="/web_fonts/kokumin/kokumr_1.00_subset.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="license"
        type="text/plain"
        href="/web_fonts/font_license.txt"
      />
    </Helmet>
  )
}

export default GlobalHelmet
