import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import { common_color } from '../../style/common_value'

import SvgTag from '../svg_components/Tag'

const tag_item_style = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '.3em .6em',
  padding: '.3em .4em',
  color: common_color.text,
  fontSize: '1em',
  lineHeight: '1em',
  border: '0.1em solid',
  borderRadius: '0.2em',
  borderColor: common_color.text,
})

const tag_icon_style = css({
  width: '1.2em',
  height: '1.2em',
  marginRight: '.5em',
  strokeWidth: '.25em',
  fill: 'none',
  stroke: common_color.text,
})

type overwrite_click_event_type = (
  event: React.MouseEvent<HTMLElement, MouseEvent>
) => void

type props_type = {
  tag_name: string
  link_tag_slug: string
  font_size?: string
  tag_margin?: string
  base_color?: string
  tag_fill?: boolean
  overwrite_click_event?: overwrite_click_event_type
}

export const TagCard: React.FC<props_type> = ({
  tag_name,
  link_tag_slug,
  font_size,
  tag_margin,
  base_color,
  tag_fill,
  overwrite_click_event,
}) => {
  return (
    <li>
      <Link
        to={`/tag/${link_tag_slug}`}
        css={css(
          tag_item_style,
          {
            color: base_color,
            borderColor: base_color,
          },
          font_size && { fontSize: font_size, lineHeight: font_size },
          tag_margin && { marginLeft: tag_margin, marginRight: tag_margin }
        )}
        onClick={overwrite_click_event}
      >
        <SvgTag
          css={css(
            tag_icon_style,
            { stroke: base_color },
            tag_fill && {
              stroke: 'none',
              fill: base_color || common_color.text,
            }
          )}
        />
        {tag_name}
      </Link>
    </li>
  )
}

export default TagCard
