import dayjs from 'dayjs'

// 入力された配列を選考からlimit_numの数だけを返す
export const limit_pickup_number = <T>(target: T[], limit_num: number): T[] => {
  let result: T[] = []
  if (target.length > limit_num) {
    for (const i of [...Array(limit_num).keys()]) {
      result.push(target[i])
    }
  } else {
    result = target
  }
  return result
}

interface blog_post_type {
  published_date: string
}

// 日付の新しい順にソートする関数
export const blog_post_sort = (
  a: blog_post_type,
  b: blog_post_type
): number => {
  return (
    new Date(b.published_date).getTime() - new Date(a.published_date).getTime()
  )
}

interface master_category_sort_input_type {
  node: {
    name?: string
    sort_order_priority?: number
  }
}

export const master_category_custom_srot = (
  a: master_category_sort_input_type,
  b: master_category_sort_input_type
): number => {
  if (a.node.sort_order_priority > b.node.sort_order_priority) return -1
  if (a.node.sort_order_priority < b.node.sort_order_priority) return 1
  return a.node.name.localeCompare(b.node.name, 'ja')
}

export const limit_number_of_characters = (
  input_string: string,
  limit_num: number
): string => {
  let result = ''
  if (input_string.length > limit_num) {
    result = `${input_string.substr(0, limit_num)}...`
  } else {
    result = input_string
  }
  return result
}

interface master_category_build_checker_type {
  node: {
    name?: string
    category?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      blog_post?: any[]
    }[]
  }
}

// マスターカテゴリーを生成するかの判定
export const master_category_build_checker = (
  input: master_category_build_checker_type
): boolean => {
  const post_length_check = []
  if (!input.node.category) {
    return false
  }
  for (const i of input.node.category) {
    if (!i.blog_post) {
      // eslint-disable-next-line no-continue
      continue
    }
    post_length_check.push(i.blog_post.length)
  }
  if (
    post_length_check === [] ||
    post_length_check.every((value) => value === 0)
  ) {
    return false
  }
  return true
}

// dayjsによる日付変換
export const date_format_ja_method = (input: string): string => {
  return dayjs(input).format('YYYY年MM月DD日')
}
export const date_format_dot_separate = (input: string): string => {
  return dayjs(input).format('YYYY.MM.DD')
}
export const date_format_for_rss = (input: string): string => {
  return dayjs(input).format('ddd, DD MMM YYYY, h:mm:ss +0900')
}

// 本文文字列の修正

export const adjust_main_text = (input_text: string): string => {
  // 絵文字の修正
  const peplace_emoji_text = input_text.replace(
    /🥴/g,
    '<span class="wavy_mouth_emoji">Woozy Face Emoji</span>'
  )
  // 文章の最初にhタグのトップマージンを消す為の仮要素
  return `<div class="the_beginning_of_the_sentence"></div>${peplace_emoji_text}`
}

// 2つの日付文字列の内、日付が新しい方を返す
export const compare_dates_return_newer = (
  input_day1: string | null,
  input_day2: string | null
): string | null => {
  // ガード節
  if (input_day1 && !input_day2) return input_day1
  if (!input_day1 && input_day2) return input_day2
  if (!input_day1 && !input_day2) return null

  const day1 = dayjs(input_day1)
  const day2 = dayjs(input_day2)
  return day1.isAfter(day2) ? input_day1 : input_day2
}
