import React, { useContext } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { css } from '@emotion/react'
import { motion } from 'framer-motion'

import { OrganismsSidemenuCategorylistQuery } from '../../../../types/graphql-types'
import {
  master_category_build_checker,
  master_category_custom_srot,
} from '../../../utils'
import { media_mobile } from '../../../style/common_value'
import { side_menu_children_variant } from './index'

import { side_menu_close } from '../../templates/Layout'
import SvgCategoryFill from '../../svg_components/CategoryFill'

const master_category_container_style = css({
  display: 'flex',
  justifyContent: 'center',
  a: {
    padding: '1.5rem .5rem 1rem',
    // fontSize: '1.3em',
    display: 'flex',
    alignItems: 'center',
    [media_mobile.tab]: {
      padding: '2rem 1rem 1rem',
    },
    svg: {
      fill: 'white',
      marginRight: '.8rem',
    },
  },
})

const category_container_style = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: '.8em',
  a: {
    width: ' fit-content',
  },
  li: {
    display: 'inline-block',
    width: 'auto',
    padding: '1rem',
    [media_mobile.tab]: {
      padding: '1rem',
    },
  },
})

type category_list_style =
  OrganismsSidemenuCategorylistQuery['allContentfulMasterCategory']['edges'][0]['node']['category']

type category_custom_srot_input_type = category_list_style[0]

const category_custom_sort = (
  a: category_custom_srot_input_type,
  b: category_custom_srot_input_type
): number => {
  return a.name.localeCompare(b.name, 'ja')
}

const category_list = (
  input_category: category_list_style,
  overwrite_click_event?: overwrite_click_event_type
): (JSX.Element | JSX.Element[])[] => {
  const result = []
  if (input_category) {
    input_category.sort(category_custom_sort)
    for (const i of input_category) {
      result.push(
        i.blog_post && (
          <motion.div
            variants={side_menu_children_variant}
            key={`category_list_${i.id}`}
          >
            <Link
              to={`/${i.master_category.slug}/${i.slug}`}
              onClick={overwrite_click_event}
              key={i.id}
            >
              <li className="category_item">{i.name}</li>
            </Link>
          </motion.div>
        )
      )
    }
  }
  return result
}

type overwrite_click_event_type = (
  event: React.MouseEvent<HTMLElement, MouseEvent>
) => void

const CategoryList: React.FC = () => {
  const data: OrganismsSidemenuCategorylistQuery = useStaticQuery(graphql`
    query OrganismsSidemenuCategorylist {
      allContentfulMasterCategory {
        edges {
          node {
            name
            slug
            id
            sort_order_priority
            category {
              name
              slug
              id
              blog_post {
                id
              }
              master_category {
                slug
              }
            }
          }
        }
      }
    }
  `)
  const overwrite_click_event = useContext(side_menu_close)
  const { edges } = data.allContentfulMasterCategory
  edges.sort(master_category_custom_srot)
  return (
    <ul className="category_list_wrapper">
      {edges.map((edge) => {
        return (
          master_category_build_checker(edge) && (
            <li key={edge.node.id}>
              <motion.div
                css={master_category_container_style}
                variants={side_menu_children_variant}
              >
                <Link to={`/${edge.node.slug}`} onClick={overwrite_click_event}>
                  <SvgCategoryFill />
                  <span>{edge.node.name}</span>
                </Link>
              </motion.div>
              <ul css={category_container_style}>
                {category_list(edge.node.category, overwrite_click_event)}
              </ul>
            </li>
          )
        )
      })}
    </ul>
  )
}

export default CategoryList
