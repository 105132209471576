import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'

import useHeader from './useHeader'

import { media_mobile, header_height } from '../../../style/common_value'

import { SvgAbarabakuhatsuTitle } from '../../svg_components/AbarabakuhatsuTitle'
import MenuButton from './MenuButton'
import RssButton from './RssButton'

const header_style = css({
  zIndex: 100,
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: header_height,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  transition: 'all 0.1s',
  boxSizing: 'content-box',
  overflow: 'hiden',
})

const header_title_style = css({
  margin: '1rem .8rem',
  [media_mobile.tab]: {
    margin: '.8rem',
  },
})

const svg_title_style = css({
  width: '150px',
  transition: 'fill 0.5s',
  [media_mobile.tab]: {
    width: '200px',
  },
})

const header_right_items = css({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
})

const Header: React.FC = () => {
  const [
    header_background,
    title_click_state,
    overwrite_click_event,
    title_view_title,
  ] = useHeader()

  return (
    <header css={[header_style, header_background]}>
      <div css={css([header_title_style, title_click_state])}>
        <Link to="/" onClick={overwrite_click_event}>
          <SvgAbarabakuhatsuTitle
            css={css([svg_title_style, title_view_title])}
          />
        </Link>
      </div>
      <div css={header_right_items}>
        <RssButton />
        <MenuButton />
      </div>
    </header>
  )
}

export default Header
