import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgRssFeed: React.FC<props_type> = (props) => {
  return (
    <svg width={24} height={24} viewBox="0, 0, 24, 24" {...props}>
      <path d="M6.503 20.752A3.25 3.25 0 013.252 24 3.25 3.25 0 010 20.752a3.25 3.25 0 013.252-3.248 3.25 3.25 0 013.251 3.248zM0 8.18v4.811c6.05.062 10.96 4.966 11.022 11.009h4.817C15.777 15.29 8.721 8.242 0 8.18zm0-3.368C10.58 4.858 19.152 13.406 19.183 24H24C23.97 10.769 13.245.046 0 0v4.812z" />
    </svg>
  )
}
export default SvgRssFeed
