import React from 'react'

export const SvgTag: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <svg
      viewBox="0 0 64 64"
      aria-labelledby="title"
      aria-describedby="desc"
      {...props}
    >
      <title>タグ</title>
      <path
        data-name="layer1"
        d="M59.6 4.4L38.4 3 3 38.4 25.6 61 61 25.6zm-8.5 14.2a4 4 0 110-5.7 4 4 0 010 5.7z"
      />
    </svg>
  )
}
export default SvgTag
