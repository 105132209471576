import { useContext, useState, useEffect } from 'react'

import { common_color } from '../../../style/common_value'
import {
  blog_post_bottom_aside_inview_context,
  footer_inview_context,
  header_image_inview_context,
} from '../../templates/Layout'

type container_css_state_type = {
  opacity: string
  cursor: string
}
type title_css_state_type = {
  color: string
}
type svg_css_state_type = {
  stroke: string
}
type use_back_to_top_desktop_return_type = [
  container_css_state_type,
  title_css_state_type,
  svg_css_state_type
]

export const useBackToTopDesktop = (): use_back_to_top_desktop_return_type => {
  const view_header_image = useContext(header_image_inview_context)

  const aside_inview = useContext(blog_post_bottom_aside_inview_context)
  const footer_inview = useContext(footer_inview_context)

  const [container_css_state, set_container_css_state] = useState({
    opacity: '0',
    cursor: 'default',
  })
  const [title_css_state, set_title_css_state] = useState({
    color: common_color.text,
  })
  const [svg_css_state, set_svg_css_state] = useState({
    stroke: common_color.text,
  })

  useEffect(() => {
    if (view_header_image) {
      set_container_css_state({ opacity: '0', cursor: 'default' })
    } else {
      set_container_css_state({ opacity: '1', cursor: 'pointer' })
    }
  }, [view_header_image])

  useEffect(() => {
    if (aside_inview || footer_inview) {
      set_title_css_state({ color: 'white' })
      set_svg_css_state({ stroke: 'white' })
    } else {
      set_title_css_state({ color: common_color.text })
      set_svg_css_state({ stroke: common_color.text })
    }
  }, [aside_inview, footer_inview])

  return [container_css_state, title_css_state, svg_css_state]
}

export default useBackToTopDesktop
