import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgBackToTop: React.FC<props_type> = (props) => {
  return (
    <svg
      data-name="back_to_top"
      viewBox="0 0 67.33 482"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1.6rem"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <title>トップへ戻る</title>
      <path
        fill="none"
        // stroke="#231815"
        strokeMiterlimit={10}
        // strokeWidth={3}
        d="M7.33 61.5L58.83 10v472"
      />
    </svg>
  )
}
export default SvgBackToTop
