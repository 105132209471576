import React from 'react'

export const SvgAbarabakuhatsuTitle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => {
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <svg viewBox="0 0 650.5 86.7" {...props}>
      <title>アバラバクハツ</title>
      <g id="abarabakuhatsu_title_svg__\u30ED\u30B4\u30BF\u30A4\u30C8\u30EB">
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M80.71 13.61l.38 1.33q-1.62 1.91-1.62 7.71V25c0 3.74-.73 6.68-2.19 8.8a18.94 18.94 0 01-7.23 5.76L52 48.92v6.28q0 8.19-8.37 16.65a61.72 61.72 0 01-13.8 10.28 4.15 4.15 0 01-3.43.38 23.48 23.48 0 00-6.56-.85 20.41 20.41 0 00-13.56 4.76l-1-.86a30.34 30.34 0 017.61-10.18A18 18 0 0123 70.43V33.69a6.94 6.94 0 00-.38-1.43 6.15 6.15 0 01-.38-1.71c0-.89.54-1.33 1.62-1.33s1.71.44 1.71 1.33a6.15 6.15 0 01-.38 1.71 6.94 6.94 0 00-.38 1.43v6.76a19.87 19.87 0 016.76-7.62 18.11 18.11 0 0110.21-3.23q6.09 0 9.22 2.4a5.22 5.22 0 012.48 4.28 14.19 14.19 0 01-.75 2.72 15.07 15.07 0 00-.77 4.9v2.76l5.24-2.76q4.95-2.48 4.95-12.56v-2.41a15.56 15.56 0 011.14-5.61 29.33 29.33 0 01-3.24.19 16.63 16.63 0 01-8.32-2.24c-2.64-1.49-4.3-2.24-5-2.24-1 0-3 1-5.9 3a15.42 15.42 0 01-8.76 3q-5.33 0-11.56-3.85T8.85 17.32q-6.28 0-7.23 9.61H.29A23.12 23.12 0 010 23.32c0-5.2 1.49-9.43 4.47-12.66s7.14-4.85 12.47-4.85q3 0 9 3a27 27 0 0012 3q4.38 0 9.43-3.62A32.62 32.62 0 0054.63 1a50.54 50.54 0 0011.89 8.52q7.91 4.1 14.19 4.09z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M33.31 38.83a8.77 8.77 0 00-6 2.14 7.19 7.19 0 00-2.43 5.66v2.19a15.22 15.22 0 007 1.72 16.16 16.16 0 005.12-.86v-5.33a9.42 9.42 0 00-.57-4c-.49-1.01-1.53-1.52-3.12-1.52zM37 60.62v-9.13a16.15 16.15 0 01-5.14.85 17.07 17.07 0 01-7-1.52v10.47a17.23 17.23 0 017-1.52 16.15 16.15 0 015.14.85zM35.5 72.52q1.43 0 1.52-10.09a16.15 16.15 0 00-5.14-.85 15.21 15.21 0 00-7 1.71v7.14a28.12 28.12 0 0110.62 2.09zM177 65.57l1.14.76a100.65 100.65 0 01-10.28 15.33c-.89 1.14-2.31 1.61-4.28 1.42a36.69 36.69 0 01-8.09-1.8 3.67 3.67 0 01-2.66-3l-4.93-28.12H135v19.51a100.46 100.46 0 01-12.9 13.41 4.07 4.07 0 01-4.47.58 25.18 25.18 0 00-9.13-1.72q-9.44 0-14.66 3.81l-1-.86a25.9 25.9 0 018.18-10.47q2.48-3 3.71-10.46l3-16.75a16.83 16.83 0 00-12 8.94l-1.52-.76a22.85 22.85 0 015.52-9.85 18.29 18.29 0 019.33-5.47l3.61-20.75a19.09 19.09 0 016.57-11.23 23.45 23.45 0 0115.51-5.71l.38 1.52q-5.7 1.25-7.8 13l-7.8 44.64a19.79 19.79 0 01-4 8.47 28.6 28.6 0 0110.75 2.85 2.48 2.48 0 002.57-.09 45.72 45.72 0 004.29-3.34V31.12q0-15.6 12.94-17.89a21.71 21.71 0 013.81-.38q7.41 0 8.27 5.23a4.39 4.39 0 01-.09 2 18 18 0 00-.29 2.86 16.1 16.1 0 00.29 2.95l7.52 42.73a2.62 2.62 0 001.62 2 8.43 8.43 0 002.85.66 4.19 4.19 0 002-.19q1.72-.75 4.94-5.52z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M135 43h11.61L144 28.55q-.86-4.85-3.42-4.85a4.63 4.63 0 00-4.24 2.23 9.72 9.72 0 00-1.34 5.19zM163.69 18.37l-2-2.29a8.18 8.18 0 01-1.81-5.42 10 10 0 012.28-6.38l1.44.72a2.73 2.73 0 00-.29 1.24c0 1.08.73 2.5 2.19 4.28l2 2.28a8.22 8.22 0 011.91 5.43 10 10 0 01-2.41 6.32l-1.43-.66a2.75 2.75 0 00.28-1.24q.03-1.65-2.16-4.28zM172.45 13l-1-1.14a7.79 7.79 0 01-1.8-5.43A10.37 10.37 0 01171.88 0l1.43.67A2.72 2.72 0 00173 1.9q0 1.62 2.19 4.29l1 1.23a8.21 8.21 0 011.9 5.43 10.18 10.18 0 01-2.38 6.37l-1.43-.66a2.73 2.73 0 00.29-1.24c.07-1.08-.66-2.5-2.12-4.32zM269.72 33.69l.38 1.31q-4.49 2.67-4.48 12.85v7.71q0 7.8-8.94 16.37a67.29 67.29 0 01-14.28 10.49 6.21 6.21 0 01-5 .57 45.18 45.18 0 00-13.32-2.28 29 29 0 00-18 6l-.95-.76q6.94-10.27 15.7-14.28V57a7.55 7.55 0 00-.33-1.43 6.83 6.83 0 01-.33-1.71c0-.89.54-1.34 1.61-1.34s1.62.45 1.62 1.34a6.15 6.15 0 01-.38 1.71 6.94 6.94 0 00-.32 1.43v13.9a23.32 23.32 0 018.47-1.62 37 37 0 014.76.29V39.5l-5.23 3.33a14.32 14.32 0 01-7.81 2.38q-5 0-11.09-4t-11.37-4q-6.09 0-7 9.61h-1.33a26.79 26.79 0 01-.19-3.23 17.9 17.9 0 014.85-13q4.86-5 13.23-5 3.8 0 9 3.28a18.55 18.55 0 009.85 3.29q4.47 0 9.33-3.43a30.07 30.07 0 007.33-7.14 44.4 44.4 0 0011 8.19q7.22 3.91 13.22 3.91zM217 14.08q-10.38 0-15.42 4.19l-1-.85a30.86 30.86 0 018.66-11 20.24 20.24 0 0113-4.76A23.85 23.85 0 01231.69 4a24.56 24.56 0 009.76 2.38q11.42 0 15.51-4.29l1 .76q-7.7 16-21.69 16a22.12 22.12 0 01-8.76-2.38A25.22 25.22 0 00217 14.08z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M249.73 52.44a16.9 16.9 0 013.62-9.61 24.86 24.86 0 01-3.14.19 16.54 16.54 0 01-8.21-1.91c-2.25-1.26-3.66-1.9-4.23-1.9v13.23zM248.11 72.81q1.62 0 1.62-11.9v-1.33h-12v10.18a37.6 37.6 0 0110.38 3.05zM367.36 65.57l1.14.76a100.63 100.63 0 01-10.27 15.33q-1.34 1.71-4.29 1.42a36.82 36.82 0 01-8.09-1.8 3.67 3.67 0 01-2.66-3l-4.95-28.07H325.3v19.46a101.09 101.09 0 01-12.85 13.41 4.09 4.09 0 01-4.48.58 25.14 25.14 0 00-9.13-1.72q-9.42 0-14.66 3.81l-.95-.86a25.84 25.84 0 018.19-10.47q2.46-3 3.71-10.46l2.95-16.75a16.8 16.8 0 00-12 8.94l-1.53-.76a23 23 0 015.52-9.85 18.37 18.37 0 019.33-5.47l3.6-20.75a19.13 19.13 0 016.56-11.23 23.46 23.46 0 0115.52-5.71l.38 1.52q-5.72 1.25-7.81 13l-7.8 44.64a19.68 19.68 0 01-4 8.47 28.7 28.7 0 0110.76 2.85 2.48 2.48 0 002.57-.09 46.84 46.84 0 004.28-3.34V31.12q0-15.6 12.94-17.89a21.89 21.89 0 013.81-.38q7.42 0 8.28 5.23a4.57 4.57 0 01-.1 2 17.83 17.83 0 00-.28 2.86 16.06 16.06 0 00.28 2.95l7.52 42.73a2.62 2.62 0 001.62 2 8.48 8.48 0 002.85.66 4.17 4.17 0 002-.19q1.74-.75 4.98-5.52z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M325.3 43h11.61l-2.57-14.47c-.57-3.23-1.72-4.85-3.43-4.85a4.61 4.61 0 00-4.23 2.23 9.72 9.72 0 00-1.38 5.19zM354 18.37l-2-2.29a8.18 8.18 0 01-1.81-5.42 10 10 0 012.28-6.38l1.43.67a2.72 2.72 0 00-.28 1.24c0 1.08.73 2.5 2.19 4.28l2 2.28a8.22 8.22 0 011.91 5.43 10 10 0 01-2.38 6.37l-1.43-.66a2.76 2.76 0 00.29-1.24q.03-1.65-2.2-4.28zM362.79 13l-1-1.14a7.79 7.79 0 01-1.81-5.43A10.37 10.37 0 01362.22 0l1.43.67a2.72 2.72 0 00-.29 1.23q0 1.62 2.19 4.29l1.05 1.23a8.21 8.21 0 011.9 5.43 10.18 10.18 0 01-2.38 6.37l-1.42-.66a2.72 2.72 0 00.28-1.24c.02-1.08-.73-2.5-2.19-4.32zM458.54 7.42l.66 1.43q-5.32 2.19-5.33 14.28v32.64q0 8.19-8.75 16.65a65.72 65.72 0 01-14 10.19 5.85 5.85 0 01-5.05.66A37.15 37.15 0 00413.52 81q-11.13 0-17.32 6l-.95-.77A31.11 31.11 0 01412 71V50.06a6.94 6.94 0 00-.38-1.43 5.8 5.8 0 01-.38-1.71c0-.89.53-1.33 1.61-1.33s1.72.44 1.72 1.33a6.15 6.15 0 01-.38 1.71 6.3 6.3 0 00-.38 1.43v20.27a25.27 25.27 0 016.75-1h2.38V26.84a12.62 12.62 0 01-5.42-2.33 10.67 10.67 0 00-5.62-2.14 15 15 0 00-5.71.85v14a17.78 17.78 0 01-4.38 11.71q-4.95 6-14.08 7.8l-.67-1.43q5.43-2.19 5.43-14.18v-4.57h-2.67a4.39 4.39 0 00-3.85 2 7.11 7.11 0 00-1.38 3.95H383a22.74 22.74 0 01-.67-5q0-8.09 7.52-8.09h2.67V15.32a9.37 9.37 0 00-.57-4c-.51-1-1.56-1.53-3.14-1.53a4.84 4.84 0 00-3.62 2 13.08 13.08 0 00-2.57 5.14h-1.33a15.33 15.33 0 014.18-11.61 14.58 14.58 0 0111-4.76c4.06 0 7 .8 8.95 2.38a5.41 5.41 0 012.19 4.28 15.4 15.4 0 01-.71 2.76 16.1 16.1 0 00-.72 4.86V16q4.67-5.52 10.76-5.52c1.39 0 3.2.74 5.42 2.24a11.63 11.63 0 006.47 2.23 15.69 15.69 0 006.38-.95 13.17 13.17 0 003.79-3.15l1 .86a31.88 31.88 0 01-6 9.89q-4.38 4.77-9.33 5.24v11.8a15.42 15.42 0 006.66 1.52 15 15 0 006.95-1.71V28q0-6.37 5.23-12.18a26.11 26.11 0 0115.03-8.4z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M431.41 41.88a17.33 17.33 0 01-6.66-1.34V55a17.32 17.32 0 016.66-1.33 16.87 16.87 0 016.95 1.52V40.35a16.88 16.88 0 01-6.95 1.53zM436.74 73.09q1.62 0 1.62-11.9V57.1a15.71 15.71 0 00-6.95-1.62 15.43 15.43 0 00-6.66 1.52v12.57a44.71 44.71 0 0111.99 3.52zM557.7 65.57l1.15.76a101.4 101.4 0 01-10.28 15.33q-1.34 1.71-4.29 1.42a36.6 36.6 0 01-8.08-1.8 3.67 3.67 0 01-2.67-3l-4.95-28.07h-12.94v19.46a101.09 101.09 0 01-12.85 13.41 4.07 4.07 0 01-4.47.58 25.24 25.24 0 00-9.14-1.72q-9.42 0-14.66 3.81l-.95-.86a25.92 25.92 0 018.19-10.47q2.48-3 3.71-10.46l2.95-16.75a16.82 16.82 0 00-12 8.94l-1.52-.76a22.75 22.75 0 015.52-9.85 18.3 18.3 0 019.32-5.47l3.62-20.75a19.09 19.09 0 016.57-11.23 23.41 23.41 0 0115.51-5.71l.38 1.52q-5.72 1.25-7.81 13l-7.8 44.64a19.56 19.56 0 01-4 8.47A28.65 28.65 0 01507 72.9a2.48 2.48 0 002.57-.09 45.5 45.5 0 004.28-3.34V31.12q0-15.6 12.94-17.89a21.89 21.89 0 013.81-.38q7.42 0 8.28 5.23a4.39 4.39 0 01-.09 2 16.9 16.9 0 00-.29 2.86 14.66 14.66 0 00.29 2.85l7.51 42.83a2.62 2.62 0 001.62 2 8.48 8.48 0 002.86.66 3.19 3.19 0 002.47-.57 31.89 31.89 0 004.45-5.14z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M515.64 43h11.61l-2.57-14.47q-.85-4.85-3.43-4.85a4.62 4.62 0 00-4.25 2.25 9.72 9.72 0 00-1.38 5.19zM594.92 35.5a19.16 19.16 0 01-3.81 11.71q-4.67 6-13.71 7.8l-.66-1.43q5.32-2.19 5.33-14.18v-.95h-1.52a4.61 4.61 0 00-4 2.28 6.49 6.49 0 00-1.24 3.71h-1.62a21.76 21.76 0 01-.66-5q0-8 7.52-8h1.52v-8.12a11.49 11.49 0 00-.48-4c-.51-.95-1.55-1.43-3.14-1.43a4.88 4.88 0 00-3.62 1.95 12.64 12.64 0 00-2.56 5.09h-1.34a15.7 15.7 0 014-11.61 13.85 13.85 0 0110.75-4.75q10.76 0 10.76 6.66a15.28 15.28 0 01-.76 2.76 15.16 15.16 0 00-.76 4.85zM637.84 18.46q6.18 0 9.71 2.38c2 1.27 3 2.7 3 4.29a14 14 0 01-.77 2.71 15.3 15.3 0 00-.76 4.9v21.79q0 8.19-8.56 16.66a62.58 62.58 0 01-13.9 10.18 6.05 6.05 0 01-5 .67 51.55 51.55 0 00-15.51-2.29 35.54 35.54 0 00-20.08 6l-1.05-.75q11.72-16.93 28.17-16.94a48.34 48.34 0 017.71.57V5.71q-5.7 3-5.71 14V31.5a18.88 18.88 0 01-4.57 12.18 23.87 23.87 0 01-14.37 8.38l-.66-1.43q5.41-2.19 5.42-14.18v-11.9q0-6.37 5-12.18a25.46 25.46 0 0114.75-8.28 9.47 9.47 0 01-.47-2.19c0-.88.53-1.33 1.61-1.33s1.62.45 1.62 1.33a6.63 6.63 0 01-.42 1.76 7.74 7.74 0 00-.38 1.48v22.94a16.15 16.15 0 016.23-6.95 16.6 16.6 0 018.99-2.67z"
        />
        <path
          className="abarabakuhatsu_title_svg__cls-1"
          d="M629.18 27.69q-6.57 0-6.57 6.76v7.81a14.67 14.67 0 005.43 1 14.88 14.88 0 005.71-1.14v-8.91c0-3.67-1.53-5.52-4.57-5.52zM622.61 44.16v11.71a15.9 15.9 0 015.43-1 17.33 17.33 0 015.71 1V44.06A17.33 17.33 0 01628 45a17.66 17.66 0 01-5.39-.84zM632.22 71.85q1.53 0 1.53-11.89v-2.19a16 16 0 00-11.14-.1V69a71.1 71.1 0 019.61 2.85z"
        />
      </g>
    </svg>
  )
}
export default SvgAbarabakuhatsuTitle
