import React from 'react'

type props_type = React.SVGProps<SVGSVGElement>

export const SvgHomeFill: React.FC<props_type> = (props) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <title>トップページへ戻る</title>
      <path d="M12 1L0 13h3v10h18V13h3L12 1zm0 18c-1.607-1.626-3-2.84-3-4.027 0-1.721 2.427-2.166 3-.473.574-1.695 3-1.246 3 .473 0 1.187-1.393 2.402-3 4.027zm8-11.907l-3-3V2h3v5.093z" />
    </svg>
  )
}
export default SvgHomeFill
