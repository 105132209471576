import { useContext, useState, useEffect } from 'react'

import { common_color } from '../../../style/common_value'

import {
  blog_post_bottom_aside_inview_context,
  footer_inview_context,
  header_image_inview_context,
  side_menu_state_context,
} from '../../templates/Layout'

const container_css_transition = 'opacity .5s ease-in-out' // all .3s ease-in-out,

const footer_transition_time = '.3s'
const container_color_transition = `${container_css_transition}, background-color ${footer_transition_time} ease-in-out, border-top ${footer_transition_time} ease-in-out, border-left ${footer_transition_time} ease-in-out, transform ${footer_transition_time}` // all .5s ease-in-out,
const title_and_svg_transition = `color ${footer_transition_time} ease-in-out, stroke ${footer_transition_time} ease-in-out`

type container_css_state_type = {
  opacity: string
}
type container_color_state_type = {
  backgroundColor: string
  borderTop: string
  borderLeft: string
  transition: string
}
type title_css_state_type = {
  color: string
  transition: string
}
type svg_css_state_type = {
  stroke: string
  transition: string
}
type use_back_to_top_mobile_return_type = [
  container_css_state_type,
  container_color_state_type,
  title_css_state_type,
  svg_css_state_type
]

export const useBackToTopMobile = (): use_back_to_top_mobile_return_type => {
  const side_menu_state = useContext(side_menu_state_context)
  const view_header_image = useContext(header_image_inview_context)

  const aside_inview = useContext(blog_post_bottom_aside_inview_context)
  const footer_inview = useContext(footer_inview_context)

  // この二つは同一オブジェクトに適応される
  // transitionは重複するので、一つに統一して設定する
  const [container_css_state, set_container_css_state] = useState({
    opacity: '0',
  })
  const [container_color_state, set_container_color_state] = useState({
    backgroundColor: 'white',
    borderTop: `1px solid ${common_color.gray}`,
    borderLeft: `1px solid ${common_color.gray}`,
    transition: container_color_transition,
  })

  const [title_css_state, set_title_css_state] = useState({
    color: common_color.text,
    transition: title_and_svg_transition,
  })

  const [svg_css_state, set_svg_css_state] = useState({
    stroke: common_color.text,
    transition: title_and_svg_transition,
  })

  useEffect(() => {
    if (view_header_image) {
      set_container_css_state({
        opacity: '0',
      })
    } else if (side_menu_state) {
      set_container_css_state({
        opacity: '0',
      })
    } else {
      set_container_css_state({
        opacity: '1',
      })
    }
  }, [view_header_image, side_menu_state])

  useEffect(() => {
    if (aside_inview || footer_inview) {
      set_container_color_state({
        backgroundColor: 'transparent',
        borderTop: `0px solid transparent`,
        borderLeft: `0px solid transparent`,
        transition: container_color_transition,
      })
      set_title_css_state({
        color: 'white',
        transition: title_and_svg_transition,
      })
      set_svg_css_state({
        stroke: 'transparent',
        transition: title_and_svg_transition,
      })
    } else {
      set_container_color_state({
        backgroundColor: 'white',
        borderTop: `1px solid ${common_color.gray}`,
        borderLeft: `1px solid ${common_color.gray}`,
        transition: container_color_transition,
      })
      set_title_css_state({
        color: common_color.text,
        transition: title_and_svg_transition,
      })
      set_svg_css_state({
        stroke: common_color.text,
        transition: title_and_svg_transition,
      })
    }
  }, [aside_inview, footer_inview])

  return [
    container_css_state,
    container_color_state,
    title_css_state,
    svg_css_state,
  ]
}

export default useBackToTopMobile
